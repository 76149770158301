import React from "react"
import Layout from "@components/Layout"
import {
  Grid,
  Box,
  Heading,
  VStack,
  Tag,
  Wrap,
  WrapItem,
} from "@chakra-ui/react"
import { graphql, Link } from "gatsby"
import tagProps from "@utils/tagProps"
import PostItem from "@components/PostItem"
import SEO from "@components/SEO"
import dataNormaliser from "@utils/dataNormaliser"

export default function HomePage({ data: _data }) {
  const data = normaliseQuery(_data)

  return (
    <Layout>
      <SEO
        title={"Learn Coding and Web Development"}
        description={""}
        // ogImage={data.post.mainImage.fluid.src}
      />
      <Grid
        py="4"
        gap="4"
        gridTemplateColumns={[`100%`, null, null, `2fr 5fr`, `240px 2fr 1fr`]}
      >
        <Box>
          <aside aria-label="Primary sidebar">
            {/* <Collapse in={true}> */}
            <VStack spacing="2" align="start">
              <Heading as="h2" fontSize="1.5rem" fontWeight="bold">
                <Box as="br" display={["none", null, null, "inline"]} />
                Tags
              </Heading>
              {!!data.categories?.length && (
                <Wrap>
                  {data.categories?.map((category, categoryIndex) => {
                    return (
                      <WrapItem key={categoryIndex}>
                        <Tag
                          as={Link}
                          to={`/category/${category.slug}`}
                          size={"lg"}
                          variant="solid"
                          colorScheme={category.color}
                          {...tagProps(category)}
                        >
                          {category.title}
                        </Tag>
                      </WrapItem>
                    )
                  })}
                </Wrap>
              )}
            </VStack>
            {/* </Collapse> */}
          </aside>
        </Box>
        <Box>
          <main role="main">
            <VStack spacing="2" align="start">
              <Heading as="h2" fontSize="1.5rem" fontWeight="bold">
                Posts
              </Heading>

              {data.posts.map((post, index) => (
                <PostItem
                  post={post}
                  key={`${post.slug}-${index}`}
                  showImage={index === 0}
                />
              ))}
            </VStack>
          </main>
        </Box>
        {/* <Box>
          <aside aria-label="Additional sidebar"/>
        </Box> */}
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    posts: allSanityPost(sort: { order: DESC, fields: publishedAt }) {
      edges {
        node {
          ...PostItem
        }
      }
    }

    # Filters by empty post array essentially
    allSanityCategory(filter: { posts: { elemMatch: { id: { ne: "" } } } }) {
      edges {
        node {
          slug {
            current
          }
          title
          color
          color_custom {
            hex
          }
        }
      }
    }
  }
`

/**
 * Because I don't trust myself not to change things, let's norm the query for display
 * @param {*} data
 */
function normaliseQuery(_data) {
  const data = {}
  data.posts =
    _data?.posts?.edges?.map(({ node: post }) =>
      dataNormaliser.normalisePost(post)
    ) || []

  data.categories =
    _data?.allSanityCategory?.edges?.map(({ node: c }) =>
      dataNormaliser.normaliseCategory(c)
    ) || []

  return data
}
